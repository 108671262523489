'use client'

import { Autoplay, EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CardComponent } from './product-card'

type Props = {
  data: any[]
}

export function SliderComponent({ data }: Props) {
  // __STATE's

  // __RENDER
  return (
    <Swiper
      slidesPerView='auto'
      effect='fade'
      speed={800}
      autoplay={{
        delay: 5e3,
        pauseOnMouseEnter: true
      }}
      loop={true}
      modules={[Autoplay, EffectFade]}>
      {data.map((record, index) => (
        <SwiperSlide key={index}>
          <CardComponent data={record} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
