'use client'

import { addHours, format, getHours, isToday, setMinutes, setSeconds, startOfToday } from 'date-fns'
import { Calendar1Icon } from 'lucide-react'
import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useEffectOnce } from 'react-use'
import { z } from 'zod'

import { APP_BASE_URL } from '@/constants/configs'
import { menu } from '@/constants/menu'
import { locales } from '@/libs/intl'
import axios from '@/services/axios'
import { generateTimeIntervals } from '@/utils'
import { zodResolver } from '@hookform/resolvers/zod'

import { dialog } from '../addons'
import { Button } from '../ui/button'
import { Calendar } from '../ui/calendar'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../ui/form'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from '../ui/select'

const formSchema = z.object({
  cs_location: z.string(),
  date: z.date(),
  cs_appdate: z.string(),
  cs_apptime: z.string(),
  cs_guest: z.string(),
  csrf: z.string().optional()
})

type Form = z.infer<typeof formSchema>

export function OnlineBookingComponent() {
  // __STATE's
  const formRef = useRef<HTMLFormElement>(null)
  const form = useForm<Partial<Form>>({ resolver: zodResolver(formSchema) })

  const locale = useLocale()
  const t = useTranslations()

  const currentLocale = locales.find((r) => r.locale === locale)!

  const [branches, setBranches] = useState<any[]>([])
  const locations = useMemo<any[]>(() => {
    if (!menu[1]?.children) return []

    return menu[1].children.map((group) => ({
      ...group,
      branches: branches.filter((branch) => group.key === `.${branch.city.toLowerCase().replace(' ', '-')}`)
    }))
  }, [branches])

  const [intervals, setIntervals] = useState(() => generateTimeIntervals('10:30', '22:30', 30))

  // __FUNCTION's
  const onSubmit = (data: Partial<Form>) => formRef.current?.submit()

  // __EFFECT's
  useEffectOnce(() => {
    axios
      .get(`/api-v2/common`, {
        baseURL: APP_BASE_URL,
        params: { locale }
      })
      .then(({ data }) => form.setValue('csrf', data.csrf_token))

    axios
      .get(`/api-v2/branches`, {
        baseURL: APP_BASE_URL,
        params: { locale }
      })
      .then(({ data }) => setBranches(data))
  })

  useEffect(() => {
    const branchId = form.watch('cs_location')
    if (branchId) {
      const branch = branches.find((r) => r.id === branchId)
      if (branch?.cross_http_origin && formRef.current) {
        formRef.current.action = `${branch.cross_http_origin}/${currentLocale.short}/onlinebooking/`
      }
    }
  }, [form.watch('cs_location'), branches, currentLocale])

  useEffect(() => {
    const appdate = form.watch('date')
    if (appdate) form.setValue('cs_appdate', format(appdate, 'yyyyMMdd'))

    if (appdate && isToday(appdate)) {
      const nextHour = addHours(Date.now(), 2)
      const roundedHour = setMinutes(setSeconds(nextHour, 0), 0)
      if (getHours(roundedHour) >= 10) {
        setIntervals(generateTimeIntervals(format(roundedHour, 'HH:mm'), '22:30', 30))
      }
    }
  }, [form.watch('date')])

  // __RENDER
  return (
    <div className='relative grid w-screen bg-white sm:w-[720px] sm:grid-cols-2'>
      <Button
        className='absolute right-2 top-2 size-10 p-0 text-zinc-500 hover:text-zinc-950'
        variant='link'
        type='button'
        onClick={() => dialog.off('md-onlinebooking')}>
        <span className='bi bi-x text-2xl' />
      </Button>

      <div className='bg-black max-sm:hidden'>
        <Image
          className='size-full object-cover object-center opacity-75'
          src='/static/images/cover.webp'
          width={320}
          height={512}
          quality={100}
          priority
          alt='Booking cover image'
        />
      </div>

      <Form {...form}>
        <form
          className='grid gap-4 px-10 pb-10 pt-14'
          action={`https://oasisspa.net/${currentLocale.short}/onlinebooking/`}
          method='POST'
          onSubmit={form.handleSubmit(onSubmit)}
          ref={formRef}>
          <input type='hidden' {...form.register('csrf')} />

          <FormField
            key='1.0'
            control={form.control}
            name='cs_location'
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel className='font-semibold'>{t('Menu.PLACEHOLDER_LOCATION')}</FormLabel>

                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger className='h-12 bg-theme-light transition-colors hover:bg-theme-100/75'>
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>

                  <SelectContent align='center'>
                    {locations.map((record) => (
                      <SelectGroup key={record.key}>
                        <SelectLabel>{t(record.label)}</SelectLabel>
                        {record.branches.map((branch: any, index: number) => (
                          <SelectItem
                            className='cursor-pointer capitalize'
                            key={`${record.key}-${index}`}
                            value={branch.id}>
                            {branch.name}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    ))}
                  </SelectContent>
                </Select>

                <FormMessage className='text-xs' />
              </FormItem>
            )}
          />

          <FormField
            key='1.1'
            control={form.control}
            name='date'
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel className='font-semibold'>{t('Menu.PLACEHOLDER_DATE')}</FormLabel>
                <input type='hidden' {...form.register('cs_appdate')} />

                <Popover>
                  <PopoverTrigger
                    className='h-12 bg-theme-light transition-colors hover:bg-theme-100/75'
                    asChild>
                    <FormControl>
                      <Button variant='outline' className='pl-3 text-left font-normal'>
                        {field.value ? format(field.value, 'PPPP') : ''}
                        <Calendar1Icon className='ml-auto h-4 w-4 opacity-50' />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>

                  <PopoverContent className='w-auto p-0' align='center'>
                    <Calendar
                      mode='single'
                      selected={field.value}
                      onSelect={field.onChange}
                      disabled={(date) => date < startOfToday()}
                    />
                  </PopoverContent>
                </Popover>

                <FormMessage className='text-xs' />
              </FormItem>
            )}
          />

          <FormField
            key='1.2'
            control={form.control}
            name='cs_apptime'
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel className='font-semibold'>{t('Menu.PLACEHOLDER_TIME')}</FormLabel>

                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger className='h-12 bg-theme-light transition-colors hover:bg-theme-100/75'>
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>

                  <SelectContent align='center'>
                    {intervals.map((record, index) => (
                      <SelectItem value={record} key={index}>
                        {record}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <FormMessage className='text-xs' />
              </FormItem>
            )}
          />

          <FormField
            key='1.3'
            control={form.control}
            name='cs_guest'
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel className='font-semibold'>{t('Menu.PLACEHOLDER_GUEST_BG')}</FormLabel>

                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger className='h-12 bg-theme-light transition-colors hover:bg-theme-100/75'>
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>

                  <SelectContent align='center'>
                    {Array.from({ length: 10 }).map((_, index) => (
                      <SelectItem value={String(index + 1)} key={index}>
                        {index + 1}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <FormMessage className='text-xs' />
              </FormItem>
            )}
          />

          <div className='flex flex-col pt-6'>
            <Button className='h-12 bg-theme p-0 text-white/75 hover:bg-theme hover:text-white' type='submit'>
              <span className='text-lg'>{t('Menu.BOOKNOW_BUTTON')}</span>
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
