'use client'

import { useTranslations } from 'next-intl'

import { dialog } from '@/components/addons'
import { OnlineBookingComponent } from '@/components/modals/booking'

export function ButtonBooking() {
  // __STATE's
  const t = useTranslations()

  // __FUNCTION's
  const handleClick = async () => {
    const name = 'md-onlinebooking'

    dialog.modal(<OnlineBookingComponent />, { name, allowEscape: true })
  }

  // __RENDER
  return (
    <>
      <button className='btn btn-primary mx-auto h-12 px-8' type='button' onClick={handleClick}>
        <span className='text z-10 text-2xl font-light uppercase tracking-widest'>
          {t('Menu.BOOKNOW_BUTTON')}
        </span>
      </button>
    </>
  )
}
