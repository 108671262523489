'use client'

import React, { useEffect, useRef, useState } from 'react'
import type { Swiper as SwiperType } from 'swiper'
import { Autoplay, EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

// Define the structure for our slide items
type SlideItem = {
  type: 'image' | 'video'
  src: string
  srcMobile: string
  alt?: string
}

type Props = {
  slides: SlideItem[]
}

export function SwiperCarousel({ slides }: Props) {
  // __STATE's
  const [swiper, setSwiper] = useState<SwiperType | null>(null)
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([])

  // __FUNCTION's
  const handleVideoEnd = (index: number) => {
    const video = videoRefs.current[index]
    const isEnded = video && video.played && video.duration <= video.currentTime + 0.2

    if (swiper && index === swiper.realIndex && isEnded) {
      swiper.slideNext()
      swiper.autoplay.start()
    }
  }

  const handleSlideChange = (swiper: SwiperType) => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        if (index === swiper.realIndex) {
          swiper.autoplay.stop()
          video.currentTime = 0
          video.play().catch((error) => console.error('Error playing video:', error))
        } else {
          video.pause()
          video.currentTime = 0
        }
      }
    })
  }

  const preloadNextVideo = (swiper: SwiperType) => {
    const nextIndex = (swiper.realIndex + 1) % slides.length
    const nextSlide = slides[nextIndex]
    if (nextSlide?.type === 'video') {
      const video = videoRefs.current[nextIndex]
      if (video) {
        video.load()
      }
    }
  }

  // __EFfECT's
  useEffect(() => {
    if (swiper) {
      swiper.on('slideChange', handleSlideChange)
      swiper.on('slideChangeTransitionEnd', preloadNextVideo)

      // Initial call to start playing the first video if it's a video slide
      handleSlideChange(swiper)
      preloadNextVideo(swiper)

      return () => {
        swiper.off('slideChange', handleSlideChange)
        swiper.off('slideChangeTransitionEnd', preloadNextVideo)
      }
    }
  }, [swiper])

  return (
    <div className='absolute size-full max-w-2k'>
      <Swiper
        modules={[EffectFade, Autoplay]}
        className='h-full'
        effect='fade'
        fadeEffect={{ crossFade: true }}
        slidesPerView={1}
        onSwiper={setSwiper}
        speed={1000}
        autoplay={{
          delay: 5e3,
          disableOnInteraction: false,
          stopOnLastSlide: false
        }}
        loop={true}>
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            {slide?.type === 'image' ? (
              <picture>
                <source media='(max-width:640px)' srcSet={slide.srcMobile} />
                <source media='(min-width:641px)' srcSet={slide.src} />
                <img src={slide.src} alt='Swiper Carousel' />
              </picture>
            ) : (
              <video
                ref={(el) => {
                  videoRefs.current[index] = el
                }}
                key={index}
                className=''
                muted
                playsInline
                preload='metadata'
                onTimeUpdate={() => handleVideoEnd(index)}
                aria-label={`Video slide ${index + 1}`}>
                <source src={slide.srcMobile} type='video/mp4' media='(max-width: 640px)' />
                <source src={slide.src} type='video/mp4' />
              </video>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
