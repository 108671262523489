import(/* webpackMode: "eager", webpackExports: ["default"] */ "/mnt/d/Projects/node/oasis_website/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/mnt/d/Projects/node/oasis_website/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/mnt/d/Projects/node/oasis_website/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/mnt/d/Projects/node/oasis_website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/mnt/d/Projects/node/oasis_website/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/mnt/d/Projects/node/oasis_website/node_modules/swiper/modules/autoplay.css");
;
import(/* webpackMode: "eager" */ "/mnt/d/Projects/node/oasis_website/node_modules/swiper/modules/effect-fade.css");
;
import(/* webpackMode: "eager" */ "/mnt/d/Projects/node/oasis_website/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager" */ "/mnt/d/Projects/node/oasis_website/node_modules/swiper/modules/pagination.css");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonBooking"] */ "/mnt/d/Projects/node/oasis_website/src/app/(public)/[locale]/home/components/btn-book.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SliderComponent"] */ "/mnt/d/Projects/node/oasis_website/src/app/(public)/[locale]/home/components/location-slide.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SliderComponent"] */ "/mnt/d/Projects/node/oasis_website/src/app/(public)/[locale]/home/components/product-slide.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SliderComponent"] */ "/mnt/d/Projects/node/oasis_website/src/app/(public)/[locale]/home/components/school-slide.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SwiperCarousel"] */ "/mnt/d/Projects/node/oasis_website/src/app/(public)/[locale]/home/components/swiper-carousel.tsx");
;
import(/* webpackMode: "eager" */ "/mnt/d/Projects/node/oasis_website/src/styles/pages/home.scss");
