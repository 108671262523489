type Props = {
  data: any
}

export function CardComponent({ data }: Props) {
  // __STATE's

  // __RENDER
  return (
    <div className='swiper-card h-80'>
      <img className='size-full object-cover object-center' src={data.image} alt={data.topic} />

      <div className='absolute inset-x-0 bottom-0 bg-theme/80 p-4 backdrop-blur-xl'>
        <a
          className='text-sm font-semibold text-theme-light'
          href={data.url}
          target='_parent'
          rel='external'
          aria-label={data.topic}>
          {data.topic}
        </a>
      </div>
    </div>
  )
}
