import { Map } from 'lucide-react'
import { useLocale } from 'next-intl'

import { configs } from '@/constants'
import { locales } from '@/libs/intl'

type Props = {
  data: any
}

export function CardComponent({ data }: Props) {
  // __STATE's
  const locale = useLocale()
  const currentLocale = locales.find((r) => r.locale === locale)!
  const publicPath = `${configs.APP_BASE_URL}/${currentLocale.short}/destination/${data.city.toLowerCase()}/${data.public_path}?utm_source=New Webapp`

  // __RENDER
  return (
    <div className='swiper-card relative'>
      <div className='relative aspect-square overflow-hidden'>
        <img className='size-full bg-gray-200 object-cover object-center' src={data.image} alt={data.name} />

        <div className='absolute inset-x-0 bottom-0 bg-theme/80 p-4 backdrop-blur-xl'>
          <div className='flex items-center justify-between gap-2 text-theme-light'>
            <div className='columns'>
              <p className='text-xs font-light uppercase'>opening hours</p>
            </div>

            <div className='columns'>
              <p className='text-sm font-semibold'>{`${data.open_timename} ~ ${data.close_timename}`}</p>
            </div>

            <div className='columns self-center'>
              <a className='' href={data.map_link} target='_parent' rel='external' aria-label={data.area}>
                <Map className='' />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className='pt-2'>
        <h2 className='line-clamp-1 font-bold leading-9 text-theme'>
          <a
            href={data?.cross_http_origin || publicPath}
            target='_parent'
            rel='external'
            aria-label={data.topic}>
            <span className='text'>{data.name}</span>
          </a>
        </h2>

        <p className='line-clamp-2 text-sm font-light text-black'>{data.medium_desc}</p>

        <a
          className='my-4 text-sm text-gray-600 underline'
          href={data?.cross_http_origin || publicPath}
          target='_parent'
          rel='external'
          aria-label={data.topic}>
          <span className='text'>Discover more</span>
        </a>

        <ul className=''>
          <li className='flex items-center gap-2'>
            <span className='icon bi bi-check-circle-fill text-theme-orange'></span>
            <span className='line-clamp-1 text-xs text-gray-800'>{data.pickupArea}</span>
          </li>

          <li className='flex items-center gap-2'>
            <span className='icon bi bi-check-circle-fill text-theme-orange'></span>
            <span className='line-clamp-1 text-xs text-gray-800'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </span>
          </li>

          <li className='flex items-center gap-2'>
            <span className='icon bi bi-check-circle-fill text-theme-orange'></span>
            <span className='line-clamp-1 text-xs text-gray-800'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}
