type Props = { data: any }

export function CardComponent({ data }: Props) {
  // __STATE's

  // __RENDER
  return (
    <div className='swiper-card'>
      <img
        className='size-full object-cover object-center'
        srcSet={`${data.mobile} 480w, ${data.desktop} 800w"`}
        sizes='(max-width: 600px) 480px, 800px'
        src={data.desktop}
        alt='Unset'
      />

      <div className='absolute inset-0 z-10 grid grid-rows-[1fr_auto]'>
        <div className='rows grid'>
          <div className='place-self-center'>
            <h2 className='text-center text-2xl text-white'>Lorem ipsum odor amet</h2>

            <button
              className='btn mx-auto mt-4 bg-theme-200 px-4 py-2 text-white'
              type='button'
              aria-label='Discover more'>
              Discover more
            </button>
          </div>
        </div>

        <div className='rows bg-theme-200/75 backdrop-blur-xl'>
          <div className='mx-auto max-w-screen-md p-4 text-center'>
            <h2 className='text-2xl font-bold text-theme'>
              Lorem ipsum odor amet, consectetuer adipiscing elit.
            </h2>

            <p className='mt-1 text-sm text-theme-light'>
              Lorem ipsum odor amet, consectetuer adipiscing elit. Arcu convallis consequat sit; posuere mi
              ultrices.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
