'use client'

import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CardComponent } from './location-card'

type Props = {
  data: any[]
}

export function SliderComponent({ data }: Props) {
  // __STATE's

  // __RENDER
  return (
    <div className='relative mx-auto w-screen px-4 md:max-w-screen-xl md:px-8'>
      <div className='absolute top-1/2 h-40 w-screen -translate-y-1/2 bg-theme-100'></div>

      <Swiper
        slidesPerView='auto'
        spaceBetween={16}
        speed={1200}
        autoplay={{
          delay: 5e3,
          pauseOnMouseEnter: true
        }}
        breakpoints={{
          640: {
            slidesPerView: 3,
            spaceBetween: 32
          }
        }}
        pagination={{
          clickable: true
        }}
        loop={true}
        centeredSlides={true}
        modules={[Autoplay, Pagination]}>
        {data.map((record, index) => (
          <SwiperSlide key={index}>
            <CardComponent data={record} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
